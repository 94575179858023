<template>
  <div class="leave-detail m-3 pb-5">
    <button @click="$router.push('/employees/' + employee.masterEmployeeId)" class="btn-larkin-outline btn-back px-3s mb-4 ml-0 ">
      <i class="fa-solid fa-arrow-left-long mr-2"></i>
      Back to Employee
    </button>

    <div class="larkin-enclosure-box desktop-width-only mb-3">
      <div class="d-flex justify-content-between" >
        <div  class="d-flex" style="margin-bottom: 1rem;">
          <img class="mr-2" style="width: 3em" src="/img/ee/pregnancy-leave.svg" v-if="leave.leaveType === 'Pregnancy'"/>
          <img class="mr-2" style="width: 3em" src="/img/ee/family-care.svg" v-else-if="leave.leaveType === 'Family Care'"/>
          <img class="mr-2" style="width: 3em" src="/img/ee/medical.svg" v-else-if="leave.leaveType === 'Medical'"/>
          <img class="mr-2" style="width: 3em" src="/img/ee/parental.svg" v-else-if="leave.leaveType === 'Parental'"/>
          <img class="mr-2" style="width: 3em" src="/img/icons/employee-icon.svg" v-else/>
          <h4> {{leave.leaveType}} Leave </h4>
        </div>
        <div class="header-table-col-wrap mr-5">
          <div class="d-inline-flex flex-wrap-reverse align-items-center data-group">
            <div class="data-header">Status</div>
            <div class="data-detail status-badge closed" v-if="leave.closed">Closed</div>
            <div class="data-detail status-badge open" v-else>Opened</div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-row leave-detail-subsection mb-3">

        <!-- Left Column -->
        <div class="flex-column flex-wrap col-6 pl-0">
          <div class="detail-header">Employee Details</div>
          <div class="d-flex">
            <div class="header-table-col-wrap mr-5">
              <div class="d-inline-flex data-group">
                <div class="data-header" style="width: 7em;">Name</div>
                <div class="data-detail">{{employee.firstName}} {{employee.lastName}}</div>
              </div>
              <div class="d-inline-flex data-group">
                <div class="data-header" style="width: 7em;">Leave Type</div>
                <div class="data-detail">{{ leave.leaveType }}</div>
              </div>
              <div class="d-inline-flex data-group">
                <div class="data-header" style="width: 7em;">Employee ID</div>
                <div class="data-detail">{{employee.employeeId}}</div>
              </div>
              <div class="d-inline-flex data-group">
                <div class="data-header" style="width: 7em;">Administrator</div>
                <div class="data-detail">{{employee.administrator}}</div>
              </div>
            </div>
            <div class="header-table-col-wrap">
              <div class="d-inline-flex data-group" v-if="employee.address">
                <div class="data-header" style="width: 7em;">Address</div>
                <div class="data-detail">
                  <div>{{employee.address.address}}</div>
                  <div v-if="employee.address.address2">{{employee.address.address2}}</div>
                  <div>{{employee.address.city}}, {{employee.address.state}} {{employee.address.zip}}</div>
                  <div v-if="employee.address.country">{{employee.address.country}}</div>
                </div>
              </div>
              <div class="d-inline-flex data-group" v-if="employee.phone">
                <div class="data-header" style="width: 7em;">Work Phone</div>
                <div class="data-detail">{{employee.phone}}</div>
              </div>
              <div class="d-inline-flex data-group">
                <div class="data-header" style="width: 7em;">Work Email</div>
                <div class="data-detail">{{employee.email}}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Right Column -->
        <div class="flex-column col-6" v-if="employee.employer">
          <div class="detail-header">Employer Information</div>
          <div class="d-flex">
            <div class="header-table-col-wrap mr-5">
              <div class="d-inline-flex data-group">
                <div class="data-header" style="width: 5em;">Employer</div>
                <div class="data-detail">{{employee.employer.name}}</div>
              </div>
              <div class="d-inline-flex data-group" v-if="employee.location">
                <div class="data-header" style="width: 5em;">Location</div>
                <div class="data-detail">{{employee.location}}</div>
              </div>
              <div class="d-inline-flex data-group">
                <div class="data-header" style="width: 5em;">Title</div>
                <div class="data-detail">{{employee.jobTitle}}</div>
              </div>
            </div>
            <div class="header-table-col-wrap">
              <div class="d-inline-flex data-group" v-if="employee.address">
                <div class="data-header">Address</div>
                <div class="data-detail">
                  <div>{{employee.employer.address.address}}</div>
                  <div v-if="employee.employer.address.address2">{{employee.employer.address.address2}}</div>
                  <div>{{employee.employer.address.city}}, {{employee.employer.address.state}} {{employee.employer.address.zip}}</div>
                  <div v-if="employee.employer.address.country">{{employee.employer.address.country}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="header-table-row-wrap mr-5">
        <div class="d-flex mt-2 mb-2 w-25">
          <img class="mr-2" style="width: 3em" src="/img/icons/calendar.svg" />
          <h4>Leave Schedule</h4>
        </div>
        <div class="d-flex w-75 justify-content-around align-items-center">
          <div class="d-flex" :class="bothHeaders === 'left' ? 'flex-row header-row' : 'flex-column ml-n5'">
            <div class="d-inline-flex data-group" v-if="leave.estimatedDeliveryDate">
              <div class="data-header">Estimated Delivery or Placement Date</div>
              <div class="data-detail">{{formatDate(leave.estimatedDeliveryDate)}}</div>
            </div>
            <div class="d-inline-flex data-group" v-if="leave.actualDeliveryDate">
              <div class="data-header">Actual Delivery or Placement Date</div>
              <div class="data-detail">{{formatDate(leave.actualDeliveryDate)}}</div>
            </div>
          </div>
          <div class="d-flex" :class="bothHeaders === 'right' ? 'flex-row header-row' : 'flex-column mr-5'">
            <div class="d-inline-flex data-group" v-if="leave.frequencyAndDurationFlareups">
              <div class="data-header">Flare-ups</div>
              <div class="data-detail">{{leave.frequencyAndDurationFlareups}}</div>
            </div>
            <div class="d-inline-flex data-group" v-if="leave.frequencyAndDurationTreatmentsAppointments">
              <div class="data-header">Treatments/Appointments</div>
              <div class="data-detail">{{leave.frequencyAndDurationTreatmentsAppointments}}</div>
            </div>
          </div>


        </div>

      </div>
      <table role="table" aria-busy="false" aria-colcount="14" class="table b-table leave-table table-row-click table-hover">
        <thead role="rowgroup">
        <tr role="row">
          <th role="columnheader" scope="col" aria-colindex="0" class="leave-col-accordion-trigger"></th>
          <th role="columnheader" scope="col" aria-colindex="1">LDW</th>
          <th role="columnheader" scope="col" aria-colindex="2">FDA Not Confirmed</th>
          <th role="columnheader" scope="col" aria-colindex="3">From</th>
          <th role="columnheader" scope="col" aria-colindex="4">Through</th>
          <th role="columnheader" scope="col" aria-colindex="5">RFL</th>
          <th role="columnheader" scope="col" aria-colindex="6">Time</th>
          <th role="columnheader" scope="col" aria-colindex="7">Work Days</th>
          <th role="columnheader" scope="col" aria-colindex="8">Work Weeks</th>
          <th role="columnheader" scope="col" aria-colindex="9">Protections</th>
          <th role="columnheader" scope="col" aria-colindex="10">Status</th>
          <th role="columnheader" scope="col" aria-colindex="11">Approved</th>
          <th role="columnheader" scope="col" aria-colindex="12">Return Confirmed</th>
          <th role="columnheader" scope="col" aria-colindex="13" v-if="showExportSuppressed">Export Suppressed</th>
          <th role="columnheader" scope="col" aria-colindex="14">Accommodation Requested</th>
          <th role="columnheader" scope="col" aria-colindex="15">Accommodation Granted</th>
        </tr>
        </thead>
        <tbody role="rowgroup" v-for="(leaveSchedule, index) in leave.details" :key="leaveSchedule.from">
          <tr role="row" tabindex="0" v-b-toggle="`leave-${index}`" class="row-drawer" :class="{ 'no-cursor' : isBlockLeaveSchedule(leaveSchedule)}">
            <td aria-colindex="0" role="cell"  v-if="!isBlockLeaveSchedule(leaveSchedule)" class="pl-1 pr-1 text-center leave-col-accordion-trigger">
              <i class="fa-solid fa-chevron-down"></i>
              <i class="fa-solid fa-chevron-up"></i>
            </td>
            <td v-else></td>
            <td aria-colindex="1" role="cell">{{formatDate(leaveSchedule.lastWorkDate)}}</td>
            <td aria-colindex="2" role="cell">{{getFdaNotApprovedText(leave.firstDayOfAbsenceNotConfirmed)}}</td>
            <td aria-colindex="3" role="cell">{{formatDate(leaveSchedule.from)}}</td>
            <td aria-colindex="4" role="cell">{{formatDate(leaveSchedule.to)}}</td>
            <td aria-colindex="5" role="cell" v-if="index === 0">{{getRflDate()}}</td>
            <td aria-colindex="5" role="cell" v-else></td>
            <td aria-colindex="6" role="cell">{{leaveSchedule.leaveScheduleType}}</td>
            <td aria-colindex="7" role="cell">{{leaveSchedule.workDays}}</td>
            <td aria-colindex="8" role="cell">{{leaveSchedule.workWeeks}}</td>
            <td aria-colindex="9" role="cell">{{getProtectionNames(leaveSchedule.protections)}}</td>
            <td aria-colindex="10" role="cell">{{leaveSchedule.status}}</td>
            <td aria-colindex="11" role="cell">{{formatDate(leaveSchedule.approvedDate)}}</td>
            <td aria-colindex="12" role="cell">{{getRtwText(leaveSchedule.returnConfirmed, leaveSchedule, false, leave)}}</td>
            <td aria-colindex="13" role="cell" v-if="showExportSuppressed">
              <b-form-checkbox v-if="isBlockLeaveSchedule(leaveSchedule)" v-model="leaveSchedule.exportSuppressed" type="checkbox" @change="updateExportSuppressed(leaveSchedule, leaveSchedule.exportSuppressed)"></b-form-checkbox>
              <span v-else>N/A</span>
            </td>
            <td aria-colindex="14" role="cell" class="pg">{{leave.accommodationRequested}}</td>
            <td aria-colindex="15" role="cell" class="pg">{{leave.accommodationGranted}}</td>
          </tr>

          <tr class="date-range-sub-table dates-row">
            <td :colspan="showExportSuppressed ? 16 : 15" class="m-0 p-0">
              <b-collapse :id="`leave-${index}`">

                <!-- Intermittent Type -->
                <table v-if="isIntermittentLeaveSchedule(leaveSchedule)" role="table" aria-busy="false" aria-colcount="3" class="table b-table date-range-table mx-5 mb-4 mt-2">
                  <thead>
                  <tr>
                    <th>From</th>
                    <th>Through</th>
                    <th>RFL</th>
                    <th class="text-nowrap">Return Confirmed</th>
                    <th class="text-nowrap" v-if="showExportSuppressed">Export Suppressed</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(dateRange, index) in leaveSchedule.dateRanges" :key="index" class="hidden-table">
                    <td aria-colindex="0">{{formatDate(dateRange.from)}}</td>
                    <td aria-colindex="1">{{formatDate(dateRange.to)}}</td>
                    <td aria-colindex="2">{{formatDate(dateRange.returnDate)}}</td>
                    <td aria-colindex="3" class="text-center">{{getRtwText(dateRange.returnConfirmed, leaveSchedule, true)}}</td>
                    <td aria-colindex="4" class="text-center" v-if="showExportSuppressed">
                      <b-form-checkbox v-if="isIntermittentLeaveSchedule(leaveSchedule)" v-model="dateRange.exportSuppressed" type="checkbox" @change="updateExportSuppressed(leaveSchedule, dateRange.exportSuppressed, dateRange.sourceEntityId)"></b-form-checkbox>
                      <span v-else>N/A</span>
                    </td>
                  </tr>

                  <tr v-if="!hasDateRangeBlock(leaveSchedule)">
                    <td :colspan="showExportSuppressed ? 5 : 4" class="text-center font-weight-bold">-- No Intermittent Blocks Found --</td>
                  </tr>
                  </tbody>
                </table>

                <!-- Manual Type -->
                <table v-if="isManualLeaveSchedule(leaveSchedule)" role="table" aria-busy="false" aria-colcount="9" class="table b-table date-range-table mx-5 mb-4 mt-2">
                  <thead>
                  <tr>
                    <th>Date</th>
                    <th>Hours Lost</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(dateRange, index) in leaveSchedule.dateRanges" :key="index">
                    <td>{{formatDate(dateRange.from)}}</td>
                    <td>{{parseFloat(dateRange.hours)}}</td>
                  </tr>
                  <tr v-if="!hasDateRangeBlock(leaveSchedule)">
                    <td colspan="2" class="text-center font-weight-bold">-- No Dates Found --</td>
                  </tr>
                  </tbody>
                </table>

                <!-- Reduced Type -->
                <table v-if="isReducedLeaveSchedule(leaveSchedule)" role="table" aria-busy="false" aria-colcount="9" class="table b-table date-range-table mx-5 mb-4 mt-2">
                  <thead>
                  <tr>
                    <th>Date</th>
                    <th>Sunday</th>
                    <th>Monday</th>
                    <th>Tuesday</th>
                    <th>Wednesday</th>
                    <th>Thursday</th>
                    <th>Friday</th>
                    <th>Saturday</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(reducedWorkSchedule, index) in leaveSchedule.reducedWorkSchedules" :key="index">
                    <td>{{formatDate(reducedWorkSchedule.startDate)}}</td>
                    <td>{{reducedWorkSchedule.sundayHours}}</td>
                    <td>{{reducedWorkSchedule.mondayHours}}</td>
                    <td>{{reducedWorkSchedule.tuesdayHours}}</td>
                    <td>{{reducedWorkSchedule.wednesdayHours}}</td>
                    <td>{{reducedWorkSchedule.thursdayHours}}</td>
                    <td>{{reducedWorkSchedule.fridayHours}}</td>
                    <td>{{reducedWorkSchedule.saturdayHours}}</td>
                  </tr>
                  <tr v-if="!hasReducedSchedules(leaveSchedule)">
                    <td colspan="8" class="font-weight-bold text-center">-- No Reduced Schedule Found --</td>
                  </tr>
                  </tbody>
                </table>

              </b-collapse>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import dateMixin from '@/mixins/dateMixin'
import leaveMixin from '../mixins/leaveMixin.js';
import formMixin from '@/mixins/formMixin'
import ApiProperties from '../../../src/main/resources/application'
import get from "just-safe-get";

export default {
  name: 'employeeDetail',
  components: {Breadcrumb},
  mixins: [dateMixin, leaveMixin, formMixin],
  props: ['leaveId', 'employeeId', 'breadcrumb'],
  data: function() {
    return {
      blockLeaveScheduleCounter: 0,
      scheduleCounter: 0,
      employee: {},
      leave: {}
    }
  },
  methods: {
    getRflDate() {
      let formattedRflDate = null;
      formattedRflDate = this.formatDate(this.leave.rflDate)
      // if (this.leave.showRflDate) {
      //   formattedRflDate = this.formatDate(this.leave.rflDate)
      //   this.leave.showRflDate = false;
      // }

      return formattedRflDate;
    },
    updateExportSuppressed: function(leaveSchedule, exportSuppressed, exportSuppressedEntityId) {
      // exportSuppressed is on the LeaveScheduleDetail for Block leaves, and is on the IntermittentBlock for Intermittent leaves.
      exportSuppressedEntityId = exportSuppressedEntityId ? exportSuppressedEntityId : leaveSchedule.id;

      const req = {
        leaveScheduleType: leaveSchedule.leaveScheduleType,
        scheduleEntityId: exportSuppressedEntityId,
        exportSuppressed: exportSuppressed
      };

      this.submit('post', ApiProperties.api.leaveUpdateExportSuppressedFlag, { pathVars: { leaveId: this.leaveId }}, req);
    }
  },
  computed: {
    showExportSuppressed: function() {
      return !!get(this.$root, 'user.allowLeaveExportSuppression');
    },
    bothHeaders: function () {
      let ret;
      if ((this.leave.frequencyAndDurationTreatmentsAppointments || this.leave.frequencyAndDurationFlareups)
          && (this.leave.actualDeliveryDate || this.leave.estimatedDeliveryDate)){
        ret = 'both';
      }else if  (this.leave.frequencyAndDurationTreatmentsAppointments || this.leave.frequencyAndDurationFlareups){
        ret = 'right';
      }else if (this.leave.actualDeliveryDate || this.leave.estimatedDeliveryDate){
        ret = 'left'
      }
      return ret;
    }
  },
  created:
      function() {
            this.$http.get(ApiProperties.api.employeeLeaveDetail, { pathVars: { leaveId: this.leaveId }}).then(response => {
              // this.employee = response.data.employee;
              this.leave = response.data.leave;
              this.leave.showRflDate = true;
            }).catch(e => {
              this.errors.push(e);
            })

            this.$http.get(ApiProperties.api.employeeDetail, { pathVars: { employeeId: this.employeeId }}).then(response => {
              this.employee = response.data;
            }).catch(e => {
              this.errors.push(e);
            })
      }
}
</script>

<style lang="scss">
.leave-detail {
  font-size: .80rem;
  max-width: 1333px;

  .larkin-enclosure-box {
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    &.desktop-width-only {
      min-width: 1150px;
      max-width: 2000px;
    }

    h4 {
      margin: 5px 0 10px 0;
      font-size: 1.1rem;
      font-weight: 550;
    }
  }

  .header-row {
    width: 100%;
    justify-content: space-around;
  }

  .detail-header {
    font-size: .65rem;
    font-weight: 900;
    text-transform: uppercase;
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  .btn-back {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 20px;
    padding: 10px 24px;
    gap: 8px;
    margin: 7px;
    border-radius: 1px !important;
    background-color: #FFF;

    &:hover {
      text-decoration: none;
      background-color: #F0F1F2;
    }

    &:enabled:active {
      color: #212529 !important;
    }
  }

  .dates-row:hover * {
    cursor: default;
  }

  .dates-row:hover * {
    cursor: default;
  }

  .no-cursor {
    cursor: unset !important;
    background-color: unset !important;
  }

  .leave-detail-subsection {
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 1.25em;
  }

  .status-badge{
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 20px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;


    &.open {
      background: #DCDEF9;
      color: #1A2499;
    }

    &.closed {
      background: #DEE1E2;
      color: #3C4244;
    }
  }

  .left-col {
    margin-right: 75px;
  }

  .row-drawer[aria-expanded=false] {
    td *.fa-chevron-up{
      display: none;
    }
  }
  .row-drawer[aria-expanded=true] {
    td *.fa-chevron-down{
      display: none;
    }
  }


  .leave-table {
    table-layout: auto !important;
    border-color: #E1E3E4;
    color: #0E1F22;
    &.table.b-table {
      tr[aria-expanded=true] {
        background-color: #F0F1F2;

      }
      tr.date-range-sub-table {
        background-color: #F0F1F2;
        .date-range-table {
          width: 280px;
          th, td {
            padding: 0.6rem 1.8rem;
            //padding-top: .3rem;
            //padding-bottom: .3rem;
          }
          th {
            text-align: left;
            background-color: #969DA0;
            color: #fff;
            text-transform: uppercase;
            border: 0;
          }

          tr {
            td {
              text-align: start;
              border-bottom: 1px solid #e7e8ea;
              //padding-top: .1rem;
              //padding-bottom: .1rem;
            }
          }
        }
      }

      thead {
        tr {
          th {
            padding: 0.85rem 0.6rem;
            text-align: start;
            line-height: inherit;
          }
        }
      }

      tr {
        td {
          padding: 1rem 0.6rem;
          border: 0;
          text-align: start;
          line-height: initial;


          .toggle-drawer {
            color: #0e1f22;
            text-decoration: none;
          }
        }
      }
    }
  }
}
</style>
